import { UPLOAD } from '../api'
import { METHOD, request } from '@/utils/request'

// 上传文件
export async function upload(params) {
  return request(`${UPLOAD}/upload`, METHOD.POST, params)
}
// 获取签名
export async function getSignature(params) {
  return request(`${UPLOAD}/getSignature`, METHOD.GET, params)
}
// 下载文件
export async function fileDownload(params) {
  return request(`${UPLOAD}/file/${params.name}`, METHOD.GET, params)
}
// 访问图片接口
export async function imageDownload(params) {
  return request(`${UPLOAD}/image/${params.name}`, METHOD.GET, params)
}
// 访问图片并生成缩略图(目前用户头像显示)
export async function imageThumbnail(params) {
  return request(`${UPLOAD}/thumbnail/${params.name}`, METHOD.GET, params)
}
// 批量获取签名
export async function getSignatures(params) {
  return request(`${UPLOAD}/getSignatures`, METHOD.GET, params)
}

