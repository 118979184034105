var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CbDrawer",
    {
      attrs: {
        title: "添加动态",
        size: "normal",
        visible: _vm.visible,
        closable: false,
        "z-index": 10,
        "confirm-text": "发布",
      },
      on: { close: _vm.onClose, onSubmit: _vm.onSubmit },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "a-form-model",
            {
              ref: "dynamicallyForm",
              attrs: {
                model: _vm.form,
                "label-col": _vm.labelCol,
                "wrapper-col": _vm.wrapperCol,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "动态标题", prop: "newsTitle" } },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder: "请输入2-30个字符",
                      "max-length": 30,
                    },
                    model: {
                      value: _vm.form.newsTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "newsTitle", $$v)
                      },
                      expression: "form.newsTitle",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "接收范围", prop: "scope" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: { placeholder: "请选择接收范围" },
                      on: { change: _vm.onChange },
                      model: {
                        value: _vm.form.scope,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "scope", $$v)
                        },
                        expression: "form.scope",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c(
                        "a-select-option",
                        { key: "" + item.value, attrs: { value: item.value } },
                        [_vm._v(" " + _vm._s(item.label) + " ")]
                      )
                    }),
                    1
                  ),
                  _vm.form.scope === "choose"
                    ? _c("CbSelectPersons", {
                        attrs: { "data-echo": _vm.perDataList },
                        on: { perModelConfirm: _vm.perModelConfirm },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "动态内容", prop: "newsText" } },
                [
                  _c("Tinymce-vue", {
                    ref: "tinymceVue",
                    attrs: { value: _vm.form.newsText, setting: _vm.setting },
                    on: {
                      setups: _vm.setups,
                      input: function (res) {
                        return (_vm.form.newsText = res)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "cenFooter" },
        [_c("a-button", { on: { click: _vm.onPreview } }, [_vm._v("预览")])],
        1
      ),
      _c("Preview", { ref: "Preview", attrs: { mode: "add" } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }